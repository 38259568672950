import {
  cooperate,
  design,
  minim,
  minimal,
  sunshi,
} from "../../../assets/images";
import Image from "../../designLayouts/Image";

import React from "react";

const Brands = () => {
  return (
    <div className="h-[5rem] mx-6 mb-16 grid grid-cols-2 sml:grid-cols-3  md:grid-cols-5  gap-10">
      <div className="flex justify-center">
        <a href="#">
          <Image imgSrc={minim} />
        </a>
      </div>

      <div className="flex justify-center">
        <a href="#">
          <Image imgSrc={minimal} />
        </a>
      </div>

      <div className="flex justify-center">
        <a href="#">
          <Image imgSrc={sunshi} />
        </a>
      </div>

      <div className="flex justify-center">
        <a href="#">
          <Image imgSrc={design} />
        </a>
      </div>

      <div className="flex justify-center">
        <a href="#">
          <Image imgSrc={cooperate} />
        </a>
      </div>
    </div>
  );
};

export default Brands;
