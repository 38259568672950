//import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
} from "../../../assets/images/index";
import React, { useEffect, useState } from 'react';

const BestSellers = () => {

  const [items, setItems] = useState([]);
  const baseUrl = 'https://back.abayafactorydubai.com/';

  useEffect(() => {
    // Fetch the data from the API
    fetch(baseUrl+'api/Item/getTopSalesItems')
      .then(response => response.json())
      .then(data => {
        if (data.statusCode === 200) {
          setItems(data.data);  // Assuming 'data.data' contains the list of products
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);


  return (
    <div className="w-full pb-20">
      <Heading heading="Our Bestsellers" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        {items.map((item) => ( 
            <Product
              _id={item.itemId}
              img={`${baseUrl}${item.imgae.replace('\\', '/')}`}  // Converting relative path to absolute URL
              productName={item.itemName}
              price={item.price.toFixed(2)}
              newprice={item.discountPrice.toFixed(2)}
              color="black"  // Assuming a color field exists or needs to be customized
              badge={item.type === 'New Arrival'}  // Condition for showing the badge
              des={item.description}
            />
        ))}
      </div>
    </div>
  );
};

export default BestSellers;
