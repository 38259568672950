import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const About = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="About Us" prevLocation={prevLocation} />
      <div className="pb-10">
        <h1 className=" text-base text-lightText mb-2 ">
          <span className="text-primeColor font-semibold text-lg">
          Welcome
          </span>{" "}
           to AbayaFactory, your premier destination for wholesale Abaya.
          Based in Dubai, we are proud manufacturers and suppliers of
          high-quality Abaya, serving businesses, boutiques, and retailers
          around the world. Our focus is exclusively on wholesale, ensuring that
          our partners receive the best products at competitive prices.
          <br/>
          <br/>
           With our
          own factory, we oversee every aspect of production, guaranteeing that
          each Abaya is crafted with care, precision, and attention to detail.
          We use only the finest fabrics and materials to create elegant, modest
          designs that resonate with today’s fashion-conscious customers.
          <br/>
          <br/>
           At
          AbayaFactory, we understand the needs of our wholesale clients. That’s
          why we offer a diverse range of styles and sizes, with the flexibility
          to accommodate bulk orders efficiently. Our streamlined manufacturing
          process, coupled with fast and reliable delivery, allows us to meet
          your demands with professionalism and consistency.
          <br/>
          <br/>
           We take pride in
          building long-term relationships with our wholesale partners, offering
          not only quality products but also exceptional customer service. Your
          success is our success, and we are committed to helping your business
          thrive with our exclusive Borka collection. 
          <br/>
          <br/>
          Thank you for choosing
          AbayaFactory as your trusted wholesale Abaya supplier. We look forward
          to being a part of your business growth.
        </h1>
        <Link to="/shop">
          <button className="w-44 h-12 rounded-[7px] bg-[#273647] text-white hover:bg-black duration-300">
            Continue Shopping
          </button>
        </Link>
      </div>
    </div>
  );
};

export default About;
