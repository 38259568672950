import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaFacebook, FaYoutube, FaTwitter, FaInstagram } from "react-icons/fa";
import FooterListTitle from "./FooterListTitle";
import { paymentCard } from "../../../assets/images";
import Image from "../../designLayouts/Image";

const Footer = () => {
  const [emailInfo, setEmailInfo] = useState("");
  const [subscription, setSubscription] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const emailValidation = () => {
    return String(emailInfo)
      .toLocaleLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };

  const handleSubscription = () => {
    if (emailInfo === "") {
      setErrMsg("Please provide an Email !");
    } else if (!emailValidation(emailInfo)) {
      setErrMsg("Please give a valid Email!");
    } else {
      setSubscription(true);
      setErrMsg("");
      setEmailInfo("");
    }
  };
  return (
    <div className="w-full bg-[#F5F5F3] py-12  responsiv">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2  xl:grid-cols-3 px-8 gap-10">
        <div className="">
          <FooterListTitle title="More about AbayaFactory" />
          <div className="flex flex-col gap-6">
            <p className="text-base text-lightText w-full xl:w-[80%]">
              It is an abaya manufacturing company. Since late three years Abaya
              has been manufacturing and supplying worldwide with
              great reputation.
            </p>
            <ul className="flex items-center gap-2">
              <li className="w-11 h-11 bg-[#273647] text-gray-100 hover:text-white cursor-pointer text-[23px] rounded-[7px] flex justify-center items-center hover:bg-black duration-300">
                <FaFacebook />
              </li>

              <li className="w-11 h-11 bg-[#273647] text-gray-100 hover:text-white cursor-pointer text-[23px] rounded-[7px] flex justify-center items-center hover:bg-black duration-300">
                <FaTwitter />
              </li>

              <li className="w-11 h-11 bg-[#273647] text-gray-100 hover:text-white cursor-pointer text-[23px] rounded-[7px] flex justify-center items-center hover:bg-black duration-300">
                <FaInstagram />
              </li>

              <li className="w-11 h-11 bg-[#273647] text-gray-100 hover:text-white cursor-pointer text-[23px] rounded-[7px] flex justify-center items-center hover:bg-black duration-300">
                <FaYoutube />
              </li>
            </ul>
          </div>
        </div>

        <div className="map-container">
          <iframe
            className="map-iframe"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3603.8146865952363!2d55.43661357538875!3d25.41101297757395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDI0JzM5LjciTiA1NcKwMjYnMjEuMSJF!5e0!3m2!1sen!2sbd!4v1726504454230!5m2!1sen!2sbd"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>

        <div className=" flex flex-col w-full px-4">
          <FooterListTitle title="Contact" />
          <div className="w-full">
            <p className="text-lightText  mb-4">
            Al Nakhil1, Ajman, Unaided Arab Emirates.
            </p>
            <p className="text-lightText mb-4">
              <a href="mailto:support@abayafactorydubai.com">
                support@abayafactorydubai.com
              </a>
            </p>

            <p className="text-lightText ">Call us : +971 56 679 7427</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
