//import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
} from "../../../assets/images/index";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import React, { useEffect, useState } from 'react';

const NewArrivals = () => {

  
  const [items, setItems] = useState([]);
  const baseUrl = 'https://back.abayafactorydubai.com/';

  useEffect(() => {
    // Fetch the data from the API
    fetch(baseUrl+'api/Item/getAllItemList')
      .then(response => response.json())
      .then(data => {
        if (data.statusCode === 200) {
          setItems(data.data);  // Assuming 'data.data' contains the list of products
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="w-full p-[1.5rem] mb-[2.5rem] ">
    <Heading heading="New Arrivals" />
    <Slider {...settings}>
    {items.map((item) => (  // Make sure 'item' is defined here within the map function
        <div className="px-2" key={item.itemId}>
          <Product
            _id={item.itemId}
            img={`${baseUrl}${item.imgae.replace('\\', '/')}`}  // Converting relative path to absolute URL
            productName={item.itemName}
            price={item.price.toFixed(2)}
            newprice={item.discountPrice.toFixed(2)}
            color="black"  // Assuming a color field exists or needs to be customized
            badge={item.type === 'New Arrival'}  // Condition for showing the badge
            des={item.description}
          />
        </div>
      ))}
    </Slider>
  </div>
  );
};

export default NewArrivals;
