import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";

const tabs = [
  { id: 1, name: "Description" },
  { id: 2, name: "Reviews" },
];

const ProductDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [prevLocation, setPrevLocation] = useState("");
  const [productInfo, setProductInfo] = useState({});
  const [activeTab, setActiveTab] = useState(tabs[0]?.id);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (location.state && location.state.item) {
      setProductInfo(location.state.item);
    }
    setPrevLocation(location.pathname);
  }, [location]);

  const handleImageClick = (product) => {
    // Navigate to the product-details page and pass the product in state
    navigate("/product-details", { state: { item: product } });
  };

  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
      <div className="max-w-container mx-auto px-4">
        <div className="xl:-mt-10 -mt-7">
          <Breadcrumbs title="" prevLocation={prevLocation} />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-4 bg-gray-100 p-4">
          <div className="h-full xl:col-span-2">
            <div>
              {/* Display the selected product image */}
              <img
                className="w-full h-full rounded-[7px]"
                src={productInfo.img || "/path/to/default-image.jpg"} // Default image if no image is available
                alt={productInfo.name || "Product Image"}
              />
            </div>
          </div>
          <div className="h-full w-full md:col-span-2 xl:col-span-4 xl:px-4 flex flex-col gap-6">
            {/* Pass product info to ProductInfo component */}
            <ProductInfo productInfo={productInfo} />
          </div>
        </div>

        {/* List of products that can be clicked to view details */}
        <div className="w-full pt-8 pb-4 grid xl:grid-cols-5 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {[
            {
              img: "/static/media/IMPRIMANTE_PANTUM_CP2200DW.06af61a2f0e80d25d4da.webp",
              name: "Multi Color Printed Shrug Abaya Borka With Stone Work",
              price: "AED 35.00",
              oldPrice: "AED 35.00",
              color: "Multi Color",
            },
            {
              img: "/static/media/ricoh4.fb59ea826525ad6dae6f.webp",
              name: "IMPRIMANTE RICOH COULEUR LASER SPC840",
              price: "AED 450",
              oldPrice: "AED 450",
              color: "Blanc",
            },
            {
              img: "/static/media/ricoh4.fb59ea826525ad6dae6f.webp",
              name: "IMPRIMANTE RICOH COULEUR LASER SPC840",
              price: "AED 450",
              oldPrice: "AED 450",
              color: "Blanc",
            },
            {
              img: "/static/media/ricoh4.fb59ea826525ad6dae6f.webp",
              name: "IMPRIMANTE RICOH COULEUR LASER SPC840",
              price: "AED 450",
              oldPrice: "AED 450",
              color: "Blanc",
            },
            {
              img: "/static/media/ricoh4.fb59ea826525ad6dae6f.webp",
              name: "IMPRIMANTE RICOH COULEUR LASER SPC840",
              price: "AED 450",
              oldPrice: "AED 450",
              color: "Blanc",
            },
          ].map((product, index) => (
            <div
              key={index}
              className="w-full relative group"
              onClick={() => handleImageClick(product)}
            >
              <div className="rounded-[7px] main-contact relative overflow-y-hidden cursor-pointer">
                <div>
                  {/* Display product image */}
                  <img className="w-full h-full" src={product.img} alt={product.name} />
                </div>
              </div>
              <div className="width w-[14rem] py-2 flex flex-col gap-1">
                <div className="items-center justify-between font-titleFont">
                  <h2 className="font-size text-[13px] leading-[1.5rem] text-primeColor font-[600]">
                    {product.name}
                  </h2>
                </div>
                <div className="flex gap-[1rem]">
                  <p className="line-through text-[#767676] text-[13px]">
                    {product.oldPrice}
                  </p>
                  <p className="text-[#273647] text-[13px]">{product.price}</p>
                 
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
