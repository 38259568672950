import { user1, user2, user3 } from "../../../assets/images";
import Image from "../../designLayouts/Image";

import React from "react";

const Reviow = () => {
  return (
      <div className="min-h-[22rem] mx-12 xs:mx-4 sml:mx-4 xs:mt-[18rem] sml:mt-[11rem] md:mt-[0rem]">
        <div>
          <h2 className="text-3xl font-semibold pb-6 xs:text-[20px] sml:text-3xl">What Our Clients Say</h2>

          <div className=" grid grid-cols-1  md:grid-cols-2 lgl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-14 xs:gap-8">
            <div className="border border-solid border-gray-300 p-[20px] rounded-[5px]">
              <div className="flex mb-[15px]">
                <div>
                  <Image className="rounded-full" imgSrc={user1} />
                </div>

                <div className="pb-[12px] pt-[12px] pl-[12px]">
                  <div>
                    <a
                      className="text-[15px] font-bold leading-[25px]"
                      href="#"
                    >
                      Patrick Goodman
                    </a>
                  </div>

                  <div className="text-[13px] text-[#777]">Manager</div>
                </div>
              </div>

              <div className="text-[14px] text-[#666] leading-[26px]">
              We ordered a large shipment of Abayas for our boutique, and I must say, the whole process was seamless. The team was professional, and the delivery was prompt.

              </div>
            </div>

            <div className="border border-solid border-gray-300 p-[20px] rounded-[5px]">
              <div className="flex mb-[15px]">
                <div>
                  <Image className="rounded-full" imgSrc={user2} />
                </div>

                <div className="pb-[12px] pt-[12px] pl-[12px]">
                  <div>
                    <a
                      className="text-[15px] font-bold leading-[25px]"
                      href="#"
                    >
                      Luies Charls
                    </a>
                  </div>

                  <div className="text-[13px] text-[#777]">CEO</div>
                </div>
              </div>

              <div className="text-[14px] text-[#666] leading-[26px]">
              I’ve been running a small retail business, and finding a reliable Abaya supplier was a challenge until I came across this factory. They offer beautiful designs at competitive prices, and the quality is just perfect. Very happy with their service!

              </div>
            </div>

            <div className="border border-solid border-gray-300 p-[20px] rounded-[5px]">
              <div className="flex mb-[15px]">
                <div>
                  <Image className="rounded-full" imgSrc={user3} />
                </div>

                <div className="pb-[12px] pt-[12px] pl-[12px]">
                  <div>
                    <a
                      className="text-[15px] font-bold leading-[25px]"
                      href="#"
                    >
                      Jecob Goeckno
                    </a>
                  </div>

                  <div className="text-[13px] text-[#777]">Unit Manager</div>
                </div>
              </div>

              <div className="text-[14px] text-[#666] leading-[26px]">
              I have been purchasing Borka from this shop for a few months now, and the quality never disappoints. Their designs are elegant, and the fabric is extremely comfortable.
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Reviow;
