import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  bannerImgOne,
 
} from "../../assets/images";
import Image from "../designLayouts/Image";

const CustomSlide = ({ Subtext, imgSrc, text, buttonLink, buttonText }) => (
  <div
    style={{
      position: "relative",
      backgroundColor: "#F5F5F3", // Gray background color
      display: "flex",
      // justifyContent: "center",
      alignItems: "center", // Center vertically
      paddingLeft: "50px",
    }}
  >
    <div
      style={{
        maxWidth: "450px", // Adjust the maxWidth as needed
        marginRight: "100px", // Add margin between text/button and image
        height: "420px",
        marginTop: "50px",
      }}
    >
      <h1
        style={{
          marginBottom: "15px",
          fontSize: "2.5rem", // Adjust the font size as needed
          color: "#000", // Black color
          fontWeight: "700",
        }}
      >
        {text}
      </h1>
      <p
        style={{
          marginBottom: "25px",
          fontSize: "1.5rem", // Adjust the font size as needed
          color: "#666", // Gray color
        }}
      >
        {Subtext}
      </p>

      <Link to="/about">
        <button className="bg-[#273647] text-white text-lg font-bodyFont w-[160px] h-[45px] hover:bg-black rounded-[7px] duration-300 font-bold">
          {buttonText}
        </button>
      </Link>
    </div>
    <div style={{ marginLeft: "100px" }}>
      <Image imgSrc={imgSrc} />
    </div>
  </div>
);

const Banner = () => {
  const [dotActive, setDocActive] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "7%",
          transform: "translateY(-50%)",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === dotActive
            ? {
                width: "30px",
                color: "#262626",
                borderRight: "3px #262626 solid",
                padding: "8px 0",
                cursor: "pointer",
              }
            : {
                width: "30px",
                color: "transparent",
                borderRight: "3px white solid",
                padding: "8px 0",
                cursor: "pointer",
              }
        }
      >
        0{i + 1}
      </div>
    ),
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: true,
          appendDots: (dots) => (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "2%",
                transform: "translateY(-50%)",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
          customPaging: (i) => (
            <div
              style={
                i === dotActive
                  ? {
                      width: "25px",
                      color: "#262626",
                      borderRight: "3px #262626 solid",
                      cursor: "pointer",
                      fontSize: "12px",
                    }
                  : {
                      width: "25px",
                      color: "transparent",
                      borderRight: "3px white solid",
                      cursor: "pointer",
                      fontSize: "12px",
                    }
              }
            >
              0{i + 1}
            </div>
          ),
        },
      },
    ],
  };

  // const slides = [
  //   {
  //     imgSrc: bannerImgOne,
  //     text: "Enhance Your Printing Experience",
  //     Subtext:
  //       "Explore our premium printers and consumables for exceptional results",
  //     buttonLink: "/offer",
  //     buttonText: "Shop Now",
  //   },
  //   {
  //     imgSrc: bannerImgOne,
  //     text: "Quality Printing Solutions",
  //     Subtext:
  //       "Discover our wide range of printers and consumables designed for professional printing needs.",
  //     buttonLink: "/shop",
  //     buttonText: "About-us",
  //   },
  //   {
  //     imgSrc: bannerImgOne,
  //     text: "Efficiency Redefined",
  //     Subtext:
  //       "Maximize productivity with our advanced printers and high-quality consumables. ",
  //     buttonLink: "/contact",
  //     buttonText: "Contact-us",
  //   },

  //   // Add more slides as needed
  // ];
  return (
    <div className=" image w-full bg-white  ">
      {/* <Slider {...settings}>
        {slides.map((slide, index) => (
          <CustomSlide key={index} {...slide} />
        ))}
      </Slider> */}

      <div class="banner__slider__content relative">
        <Image className="w-full sm:h-[250px] md:h-[300px] lg:h-[400px] xl:h-[520px]" imgSrc={bannerImgOne} />
        <div className="absolute xl:top-[15rem] xl:left-[10rem] sm:top-[7rem] sm:left-[4rem] md:top-[8rem] md:left-[6rem] lg:top-[11rem] lg:left-[10rem]">
          <h2 class="banner__slider__title xl:text-[72px] xl:font-medium xl:leading-[5rem] text-white mb-4 sm:text-[30px] sm:leading-[2rem] md:text-[40px] md:leading-[3rem] lg:text-[50px] lg:leading-[4rem]">
            GORGEOUS
            <br />
            <strong>ABAYA</strong>{" "}
          </h2>
          <span class=" xl:px-[28px] xl:py-[18px] xl:text-[14px] bg-[#273647] rounded-[5px] text-white sm:py-[12px] sm:px-[20px] sm:text-[10px]">
          Latest Abaya Designs
            <i class="fa-solid fa-arrow-right pl-[10px]"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Banner;
